<template>
  <div class="border-box-content" style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
  	<img style="width:85%;" :src="item.img"/>
  	<!-- <span style="color: #ffffff;font-size: 1rem;margin-top: 5%;">{{item.name}}</span> -->
	<span style="color: #ffffff;font-size: .8rem;margin-top: 5%;">{{item.name}}</span>
  </div>
</template>

<script>
export default {
  name: 'SelectRect',
  props: {
    item: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
